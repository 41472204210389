<template>
  <div>
    <component :is="layout">
      <RouterView> </RouterView>
    </component>
  </div>
</template>

<script>
import AuthLayout from '@/layouts/Auth.vue';
import DashboardLayout from '@/layouts/Dashboard.vue';
export default {
  name: 'AppPlatform',
  components: {
    DashboardLayout,
    AuthLayout,
  },
  data: function () {
    return {};
  },
  computed: {
    layout() {
      if (this.$route.meta.auth == true) {
        var dashboardLayout = 'Dashboard';
      }
      return (this.$route.meta.layout || dashboardLayout) + 'Layout';
    },
  },
  mounted() {
    if (this.$store.state.auth.authenticated) {
      this.$store.dispatch('account/find');
    }
  },
};
</script>
