import Proxy from '@/proxies/ReportProxy';
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    organisationWiseAdminConsoleData({ state, dispatch }, param) {
      return new Proxy().organisationWiseAdminConsoleData(param);
    },
  },
};
