/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

//import Vuex from 'vuex';
//import createLogger from 'vuex/dist/logger';
import { createStore } from 'vuex';

// Modules
import auth from './modules/auth';
import account from './modules/account';

import tokenverification from './modules/tokenverification';

import auditLogs from './modules/auditLogs';
// import subscription from './modules/subscription';
import auditLogTable from './modules/auditLogTable';
import settingDynamicDatatable from './modules/settingDynamicDatatable';
import iam from './modules/iam';
import Report from './modules/Report';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
  /**
   * Assign the modules to the store.
   */
  modules: {
    auth,
    account,
    tokenverification,
    auditLogs,
    auditLogTable,
    settingDynamicDatatable,
    iam,
    Report,
  },

  /**
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  //plugins: debug ? [createLogger()] : [],
});

export default store;
