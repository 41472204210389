//import Vue from 'vue';
//import VueRouter from 'vue-router';
import {
  RouterLink,
  RouterView,
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router';
import routes from '@/routes';
import store from '@/store';

//Vue.use(VueRouter);

//export const router = new VueRouter({
//  routes,
//});

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((m) => m.meta.auth) && store.state.auth.authenticated) {
    let role = store.state.auth.role;
    /* User authenticated. Check for role and redirect accordingly */
    if (to.matched.some((m) => m.meta.role == role)) {
      next();
    } else {
      next({
        name: 'login.index',
      });
    }
  } else if (
    to.matched.some((m) => m.meta.auth) &&
    !store.state.auth.authenticated
  ) {
    /*
     * If the user is not authenticated and visits
     * a page that requires authentication, redirect to the login page
     */
    next({
      name: 'login.index',
    });
  } else if (
    to.matched.some((m) => m.meta.guest) &&
    store.state.auth.authenticated
  ) {
    /*
     * If the user is authenticated and visits
     * an guest page, redirect to the dashboard page
     */
    let role = store.state.auth.role;
    if (
      to.name == 'nakkeeran.index' ||
      to.name == 'nakkeeran.validation' ||
      to.name == 'nakkeeran.datatable'
    ) {
      next();
    } else {
      // if (role == 0) {
      //   /* SUPER ADMIN home page */
      //   next({
      //     name: 'login.index',
      //   });
      // } else {
      next({
        name: 'login.index',
      });
      // }
    }
  } else {
    next();
  }
});

//Vue.router = router;

export default router;
