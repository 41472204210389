<template>
  <div class="mt-0">
    <!-- <div
      class="d-flex align-items-center justify-content-center mobile_login_container"
      v-if="browseWidth <= 768"
    >
      <div class="text-center mobile_login_view">
        <div class="mb-3">
          <img
            src="https://d3rvqdpyprgzm8.cloudfront.net/assets/logos/getryt_logo_trim.png"
            alt="nonwindowbrowser"
            v-if="getDomainName == 'Getryt'"
          />
          <img
            src="https://d3rvqdpyprgzm8.cloudfront.net/assets/logos/quantem_logo_trim.png"
            alt="nonwindowbrowser"
            v-if="getDomainName == 'Quantem'"
          />
        </div>
        <div class="mb-3">
          <img
            src="https://d3rvqdpyprgzm8.cloudfront.net/assets/mobile_login_page.jpg"
            alt="nonwindowbrowser"
          />
        </div>
        <h4 class="mobile_view_content">
          Our application does not support mobile browsers. Please login through
          desktop.
        </h4>
      </div>
    </div> -->
    <div class="">
      <div class="login_right_container login_form_right_container">
        <div class="text-end sign_up_link">
          <!-- <span
            class="login_below_img"
            v-if="
              this.$route.path !== '/signup' &&
              this.$route.path !== '/signupform'
            "
          >
            {{ $t('general.auth.login_below_img') }}
            <router-link to="/signup" class="router_tags signup_text"
              >Sign up
              <i
                class="fa fa-long-arrow-right signup_arrow_icon"
                aria-hidden="true"
              ></i
            ></router-link>
          </span> -->
          <span
            class="login_below_img"
            v-if="
              this.$route.path === '/signup' ||
              this.$route.path === '/signupform'
            "
          >
            {{ $t('general.auth.existed_account_checking') }}
            <router-link to="/login" class="router_tags font_bold signup_text">
              {{ $t('general.common.sign_in') }}
              <i
                class="fa fa-long-arrow-right signup_arrow_icon"
                aria-hidden="true"
              ></i
            ></router-link>
          </span>
        </div>
        <slot></slot>
        <!-- <div class="copyrights_footer">
          <div class="copyrights_container">
            <div class="d-flex justify-content-between footer_container">
              <div class="terms">
                <a
                  href="https://www.quantem.io/terms"
                  class="text-decoration-none font_gray"
                  target="_blank"
                  >{{ $t('general.auth.terms') }}</a
                >
              </div>
              <div class="policy">
                <a
                  href="https://www.quantem.io/privacy-policy"
                  class="text-decoration-none font_gray"
                  target="_blank"
                  >{{ $t('general.auth.policy') }}</a
                >
              </div>
              <div class="contact_us">
                <a
                  href="mailto:support@quantem.io"
                  class="font_gray"
                  target="_blank"
                  >support@quantem.io</a
                >
              </div>
            </div>
            <div class="text-center copy_rights_text">
              <span class="me-1 copyrights_symbol">&copy;</span>
              <a
                href="https://getryt.io"
                class="font_gray"
                target="_blank"
                v-if="getDomainName == 'Getryt'"
                >getryt.io</a
              >
              <a
                href="https://quantem.io"
                class="font_gray"
                target="_blank"
                v-if="getDomainName == 'Quantem'"
                >quantem.io</a
              >
              {{ $t('general.auth.copy_rights_text') }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  data() {
    return {
      browseWidth: Number,
      getDomainName: process.env.VUE_APP_DOMAIN_NAME,
    };
  },
  methods: {
    clearBrowserHistoryOnLogout() {
      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        history.go(0);
      };
    },
  },
  mounted() {
    this.clearBrowserHistoryOnLogout();
    this.browseWidth = window.innerWidth;
  },
};
</script>
