//import Vue from 'vue';
import { createI18n } from 'vue-i18n';
import messages from '@/locale';

//Vue.use(VueI18n);

/*
const messages = {
  en: {
    general: {
      dio: 'DIO',
      linked: "There's a reason, you lost, @:{'message.dio'}."
    }
  }
}
*/

let slang = window.localStorage.getItem('language') || 'en';
export const i18n = createI18n({
  locale: slang,
  fallbackLocale: 'en',
  messages,
  /*
: {
    en: {
      message: {
        language: "English",
        greeting: "Hello !"
      }
    },
  }
*/
});

export default i18n;
