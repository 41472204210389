import {
  getCookie,
  setCookie,
  removeCookie,
  checkCookie,
} from '@/utils/cookies.js';

export function getStorageItem(cname) {
  return getCookie(cname);
}

export function setStorageItem(cname, cvalue, expireDays = 0) {
  return setCookie(cname, cvalue, expireDays);
}

export function removeStorageItem(cname) {
  return removeCookie(cname);
}

export function checkStorageItem(cname) {
  return checkCookie(cname);
}
