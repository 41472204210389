import Proxy from '@/proxies/AdminProxy';
export default {
  namespaced: true,
  state: {
    responseData: null,
    organisationId: null,
    emailId: null,
    firstName: null,
    lastName: null,
    username: null,
    roleType: null,
    userid: null,
    enterprisesInformation: null,
    permissionDetails: null,
    minimizedLogo: null,
    fullLogo: null,
    timeZone: null,
    getSideBarExpandedCollapsedDetails: [],
  },
  getters: {
    getuserid(state) {
      return state.userid;
    },
    getuseruuid(state) {
      return state.uuid;
    },
    getuserfirstName(state) {
      return state.firstName;
    },
    getuserlastName(state) {
      return state.lastName;
    },
    getusername(state) {
      return state.username;
    },
    getOrganisationId(state) {
      return state.organisationId;
    },
    getOrganisationName(state) {
      return state.organisationName;
    },
    getEmailId(state) {
      return state.emailId;
    },
    getFormatedTimeZone(state) {
      return state.timeZone;
    },
    getLoginResponseData(state) {
      return state.responseData;
    },
  },
  mutations: {
    FIND(state) {},
    SAVEACCOUNT(stateData, accountDetail) {
      const state = stateData.data ? stateData.data : stateData;
      const account = accountDetail.data ? accountDetail.data : accountDetail;
      state.userid = account.userid;
      state.uuid = account.uuid;
      state.firstName = account.firstName;
      state.lastName = account.lastName;
      state.username = account.username;
      state.emailId = account.emailId;
      state.organisationId = account.organisationId;
      state.organisationName = account.organisationName;
      state.timeZone = account.timeZone;
      state.responseData = account;
    },
  },
  actions: {
    find({ commit }) {
      if (localStorage.getItem('account')) {
        var acccount;
        try {
          var decodeLocalStorageObj = atob(localStorage.getItem('account'));
          acccount = JSON.parse(decodeLocalStorageObj);
        } catch {
          var getAccountStringify = localStorage.getItem('account');
          localStorage.setItem('account', btoa(getAccountStringify));
          acccount = JSON.parse(getAccountStringify);
        }
        commit('SAVEACCOUNT', acccount);
      }
    },
    saveaccount({ commit }, loginPayload) {
      const payload = loginPayload.data ? loginPayload.data : loginPayload;
      const account = {
        responseData: payload,
        organisationId: payload.organisationId,
        userid: payload.userid,
        uuid: payload.uuid,
        firstName: payload.firstName,
        lastName: payload.lastName,
        username: payload.username,
        emailId: payload.emailId,
        organisationName: payload.organisationName,
        timeZone: payload.timeZone,
      };
      const accountStringify = JSON.stringify(account);
      localStorage.setItem('account', btoa(accountStringify));
      commit('SAVEACCOUNT', account);
    },
  },
};
