import Proxy from './Proxy';

class auditLogDetailsProxy extends Proxy {
  constructor(parameters = {}) {
    super('audit/api/v1/', parameters);
  }

  getActionLogsByOrg(param) {
    return this.submit(
      'get',
      `${this.endpoint}audit-log/getAllRecordsBy/Organisation/?organisationId=${param.organisationId}`,
    );
  }
}

export default auditLogDetailsProxy;
