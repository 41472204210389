import Proxy from './Proxy';

class AdminProxy extends Proxy {
  constructor(parameters = {}) {
    super('support', parameters);
  }

  getUserList(param) {
    return this.submit('get', `${this.endpoint}/admins/all`);
  }
  getUser(param) {
    return this.submit(
      'get',
      `${this.endpoint}/admin/?supportAdminId=${param}`,
    );
  }
}

export default AdminProxy;
