<template>
  <header>
    <div class="container-fluid header_container">
      <div
        class="d-flex flex-wrap align-items-center justify-content-end header_inner_container"
      >
        <div class="font16 font_bold text_black page_top_head_title">
          <img
            class="main_logo"
            src="../../assets/scss/logos/quantem_text_trim_white.png"
            alt="nonwindowbrowser"
          />
        </div>
        <ul class="nav nav-pills header_list_ul">
          <li class="header_list">
            <div class="dropdown text-end ml-3 me-2 header_profile">
              <a
                class="d-block link_white cursor text-decoration-none dropdown-toggle"
                id="dropdownUserHeader"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span
                  class="profile_icon rounded-circle font20"
                  :style="{ background: setColorCode }"
                  >{{ this.organisationNameFirstCharacter
                  }}<span v-if="this.organisationNameSecondCharacter">{{
                    this.organisationNameSecondCharacter
                  }}</span></span
                >
                <div class="login_user_detail">
                  <div class="user_detail_in_header user_profile_detail">
                    <div class="text-start profile_user_name">
                      <span v-if="headerUserData">{{ headerUserData }}</span>
                      <span v-else>{{ profileUsername }}</span>
                    </div>
                    <div class="user_profile_item">
                      {{ this.organisationName }}
                    </div>
                  </div>
                </div>
              </a>
              <ul
                class="dropdown-menu text-small user_profile_menu"
                aria-labelledby="dropdownUserHeader"
                aria-haspopup="true"
              >
                <li class="user_profile_detail" @click.stop>
                  <div class="d-flex align-items-center">
                    <div
                      class="profile_icon rounded-circle font20"
                      :style="{ background: setColorCode }"
                    >
                      {{ this.organisationNameFirstCharacter
                      }}<span v-if="this.organisationNameSecondCharacter">{{
                        this.organisationNameSecondCharacter
                      }}</span>
                    </div>
                    <div class="profile_details">
                      <div class="profile_user_name">
                        <span v-if="headerUserData">
                          {{ headerUserData }}
                        </span>
                        <span v-else>{{ profileUsername }}</span>
                      </div>
                      <div class="user_profile_item fw4">
                        {{ this.organisationName }}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    class="d-flex align-items-center dropdown-item"
                    @click="logout()"
                  >
                    <span>
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_14115_41139)">
                          <path
                            d="M11.999 4.5V11.625"
                            stroke="#313F58"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.5002 5.08596C17.9907 6.05606 19.1279 7.48185 19.7422 9.15083C20.3565 10.8198 20.415 12.6426 19.9091 14.3476C19.4031 16.0525 18.3598 17.5484 16.9345 18.6121C15.5093 19.6758 13.7784 20.2505 12 20.2505C10.2215 20.2505 8.4907 19.6758 7.06545 18.6121C5.64021 17.5483 4.59685 16.0525 4.0909 14.3475C3.58495 12.6426 3.64349 10.8198 4.2578 9.1508C4.8721 7.48182 6.00929 6.05603 7.49985 5.08594"
                            stroke="#313F58"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_14115_41139">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <div class="ps-2">
                      {{ $t('general.common.sign_out') }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- Access token expired get user login details modal start -->
      <div
        class="login_token_expired_modal"
        v-if="loginTokenExpired.loginTokenExpiredValue == true"
      >
        <div
          class="login_expired_form"
          :class="{
            open_expiry_modal: loginTokenExpired.loginTokenExpiredValue == true,
          }"
        >
          <div class="d-flex justify-content-between mb-3">
            <div class="confirmation_modal_title_text">
              <span>
                {{ $t('general.common.session_expired') }}
              </span>
            </div>
          </div>
          <form @submit.prevent="handleLoginSubmit()">
            <div>
              <div
                class="mb-3"
                :class="{
                  'form-group--error':
                    v$.emailId.$error || !v$.emailId.maxLength,
                }"
              >
                <label class="form_label" for="email"
                  >{{ $t('general.common.email_id') }}*</label
                >
                <div class="position-relative">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    :placeholder="$t('general.placeholder.enter_work_email')"
                    aria-describedby="emailHelp"
                    v-model.trim="v$.emailId.$model"
                    readonly
                    disabled
                  />
                  <div class="error input_error">
                    <span
                      class="d-inline-block"
                      v-if="v$.emailId.$error && v$.emailId.required.$invalid"
                    >
                      {{ $t('general.validation.email_required') }}
                    </span>
                    <span
                      class="d-inline-block"
                      v-if="v$.emailId.$error && v$.emailId.maxLength.$invalid"
                    >
                      {{ $t('general.validation.max_len_32') }}
                    </span>
                    <div>
                      <span
                        class="d-inline-block"
                        v-if="v$.emailId.$error && v$.emailId.email.$invalid"
                      >
                        {{ $t('general.validation.email_invalid') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mb-3"
                :class="{
                  'form-group--error':
                    v$.password.$error ||
                    !v$.password.maxLength ||
                    incorrectPassword,
                }"
              >
                <label class="form_label"
                  >{{ $t('general.common.password') }}*</label
                >
                <div class="position-relative">
                  <input
                    :type="passwordFieldType"
                    class="form-control"
                    id="passwordFieldHeader"
                    :placeholder="$t('general.placeholder.enter_password')"
                    v-model.trim="v$.password.$model"
                  />
                  <span
                    v-if="passwordFieldType == 'text'"
                    toggle="#passwordFieldHeader"
                    type="password"
                    @click="switchVisibility"
                    class="pi pi-eye field-icon toggle-password open_eye"
                  ></span>
                  <span
                    v-else
                    toggle="#passwordFieldHeader"
                    type="text"
                    @click="switchVisibility"
                    class="pi pi-eye-slash field-icon close_eye"
                  ></span>
                  <div class="error input_error">
                    <span
                      class="d-inline-block"
                      v-if="v$.password.$error && v$.password.required.$invalid"
                    >
                      {{ $t('general.validation.password_required') }}
                    </span>
                    <span
                      class="d-inline-block"
                      v-if="
                        v$.password.$error && v$.password.minLength.$invalid
                      "
                    >
                      {{ $t('general.validation.password_min_len_8') }}
                    </span>
                    <span
                      class="d-inline-block"
                      v-if="
                        v$.password.$error && v$.password.maxLength.$invalid
                      "
                    >
                      {{ $t('general.validation.max_len_50') }}
                    </span>
                    <span
                      class="d-inline-block"
                      v-if="!v$.password.$error && incorrectPassword"
                    >
                      {{ incorrectPasswordMsg }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div class="me-3">
                <a
                  href="#"
                  class="btn cancel_btn"
                  @click.prevent="redirectToLogin()"
                  >{{ $t('general.common.exit') }}</a
                >
              </div>
              <div>
                <button class="btn btn_blue continue_btn" type="submit">
                  {{ $t('general.auth.relogin') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Toast />
  </header>
</template>
<script>
import {
  getPermissions,
  getColorCode,
  loginTokenExpired,
  headerUserData,
  getOrganisationId,
  getUserId,
  getEnterpriseRegistrationDetails,
  handleAndroidRegistration,
  callAuthenticationData,
  getFormattedDateWithTimezone,
} from '@/utils/utils.js';
import Tooltip from 'primevue/tooltip';
import useValidate from '@vuelidate/core';
import { required, minLength, email, maxLength } from '@vuelidate/validators';
import $ from 'jquery';
import { getStorageItem } from '@/utils/storage.js';
import store from '@/store';
import BadgeDirective from 'primevue/badgedirective';

export default {
  name: 'Header',
  directives: {
    tooltip: Tooltip,
    badge: BadgeDirective,
  },
  components: {},
  props: {
    selectedThemeName: String,
    authenticationData: Boolean,
  },
  data: function () {
    return {
      sidebarZindex: 10,
      languageJson: [
        {
          displayLanguage: this.$t('general.common.english'),
          value: 'en',
        },
      ],
      organisationName: '',
      organisationPortal: null,
      selectedLanguage: null,
      organisationNameFirstCharacter: '',
      organisationNameSecondCharacter: '',
      profileUsername: '',
      setColorCode: '',
      v$: useValidate(),
      emailId: null,
      password: null,
      passwordFieldType: 'password',
      loginTokenExpired,
      expiryToken: true,
      headerUserData: '',
      organisationId: null,
      userid: null,
      incorrectPassword: false,
      incorrectPasswordMsg: '',
      routeUrl: '',
      disableHeader: '',
      routeQuery: this.$route.query,
      accessToken: null,
      timeZone: '',
    };
  },
  validations: {
    emailId: {
      required,
      email,
      maxLength: maxLength(32),
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(50),
    },
  },
  methods: {
    getColorCode: getColorCode,
    getEnterpriseRegistrationDetails: getEnterpriseRegistrationDetails,
    handleAndroidRegistration: handleAndroidRegistration,
    getFormattedDateWithTimezone: getFormattedDateWithTimezone,
    logout() {
      let dataArr = {
        userId: this.userid,
        organisationId: this.organisationId,
      };
      this.$store
        .dispatch('tokenverification/sessionLogout', dataArr)
        .then(() => {});
      this.$store.dispatch('auth/logout');
      this.$cookies.remove('getFeatureListOnce');
      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        history.go(0);
      };
    },
    langChange(getLanguage) {
      localStorage.setItem('language', getLanguage.value);
      this.selectedLanguage = getLanguage.value;
      window.location.reload();
    },
    routeToRoleMangement() {
      this.$router.push({
        path: '/roles',
      });
    },
    routeToProfileSettings() {
      this.newsfeedSidebarClose();
      this.$router.push({
        path: '/settings',
      });
    },
    handleThemeSelect(themeName) {
      this.$emit('setThemeLayout', themeName);
    },
    async getOrganisationNameFirstChar() {
      this.organisationName = this.$store.getters['account/getEmailId'];
      this.organisationNameFirstCharacter = this.firstNameLetter
        ? this.firstNameLetter.substring(0, 1).toUpperCase()
        : '';
      this.organisationNameSecondCharacter = this.lastNameLetter
        ? this.lastNameLetter.substring(0, 1).toUpperCase()
        : '';
    },
    loginDetailsCheck() {
      this.v$.$validate();
      if (!this.v$.$error) {
        return true;
      }
      return false;
    },
    handleLoginSubmit() {
      if (this.loginDetailsCheck()) {
        this.incorrectPassword = false;
        var dataarr = {
          emailId: this.emailId,
          password: this.password,
          rememberme: this.rememberme,
          expiryToken: this.expiryToken,
        };
        this.submitStatus = 'LOADING';
        let cachescope = this;
        this.$store
          .dispatch('auth/login', dataarr)
          .then(function (res) {})
          .catch(function (err) {
            cachescope.incorrectPassword = true;
            cachescope.incorrectPasswordMsg = err.message;
            setTimeout(() => {
              cachescope.incorrectPassword = false;
            }, 10000);
          });
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    redirectToLogin() {
      loginTokenExpired.close();
      store.dispatch('auth/logout');
    },
    handleClosePopup() {
      this.dashboardTourGuideModal = false;
    },
    closeModalbyDblClickAndEscape() {
      if (this.$refs.tourGuideModel) {
        if (this.$refs.tourGuideModel.tourGuideImage) {
          this.$refs.tourGuideModel.closeTourGuideModal();
        } else {
          this.handleClosePopup();
        }
      }
    },
    doubleClickSidebar(e) {
      if (
        e.target.classList.value ===
          'p-sidebar-mask p-component-overlay p-component-overlay-enter' ||
        e.target.classList.value ===
          'p-dialog-mask p-component-overlay p-component-overlay-enter'
      ) {
        this.closeModalbyDblClickAndEscape();
      }
    },
    getEscapeEvent(e) {
      if (e.key === 'Escape') {
        $('.notification_dropdown, .notification_list').removeClass('show');
        this.closeModalbyDblClickAndEscape();
      }
    },
    handleLogoutConfirm(confirm) {
      if (!confirm) {
        this.logout();
      }
    },
  },
  watch: {
    $route(to, from) {
      this.routeUrl = this.$route.path;
      this.routeQuery = this.$route.query;
    },
  },
  mounted() {
    let cachescope = this;
    this.routeUrl = this.$route.path;
    cachescope.organisationId = getOrganisationId(this);
    cachescope.userid = getUserId();
    this.timeZone = this.$store.getters['account/getFormatedTimeZone'];
    this.profileUsername = this.$store.getters['account/getusername'];
    (this.firstNameLetter = this.$store.getters['account/getuserfirstName']),
      (this.lastNameLetter = this.$store.getters['account/getuserlastName']),
      (this.setColorCode = this.getColorCode(this.organisationName));
    this.selectedLanguage = window.localStorage.getItem('language') || 'en';
    this.getOrganisationNameFirstChar();
    this.dashboardMenuAccess = getPermissions(this, ['Dashboard']);
    cachescope.emailId = this.$store.getters['account/getEmailId'];
    this.accessToken = getStorageItem('accessToken');
    this.headerUserData =
      (this.firstNameLetter
        ? this.firstNameLetter.charAt(0).toUpperCase() +
          this.firstNameLetter.slice(1).toLowerCase()
        : '') +
      ' ' +
      (this.lastNameLetter
        ? this.lastNameLetter.charAt(0).toUpperCase() +
          this.lastNameLetter.slice(1).toLowerCase()
        : '');
  },
};
</script>
