import * as XLSX from 'xlsx';
import { reactive } from 'vue';
import { getStorageItem, setStorageItem } from '@/utils/storage.js';
import router from '@/plugins/vue-router';
import axios from 'axios';
import store from '@/store';
import moment from 'moment-timezone';
import $ from 'jquery';
export function getOrganisationId(obj) {
  return obj.$store.getters['account/getOrganisationId'];
  //return 'fc49482b-98b5-41a2-8fe2-19090bd77612'; //TODO:: Fix store vue3
}
/*Getting Local storage values */
export function getUserId() {
  var userid = localStorage.getItem('userid');
  return userid;
}
/*Getting Local storage values */
export function getEmailId() {
  var emailid = localStorage.getItem('emailid');
  return emailid;
}

export function isObjectHasKey(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
export function getFormattedDate(date, inDateFormat, outDateFormat) {
  return date ? moment(date).utc().format('MMM-DD-YYYY HH:mm') : '';
}
export function cardViewFormattedDate(date, inDateFormat, outDateFormat) {
  return date ? moment(date).format('MMM-DD-YY HH:mm') : '';
}
export function getFormattedDateWithTimezone(
  date,
  timezone,
  timeFormat,
  inDateFormat,
  outDateFormat,
) {
  if (timezone === '' || timezone === null) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  if (timeFormat === 'timeRemove') {
    return date && timezone
      ? moment(date).tz(timezone).format('MMM-DD-YYYY')
      : '';
  } else {
    return date && timezone
      ? moment(date).tz(timezone).format('MMM-DD-YYYY HH:mm')
      : '';
  }
}

export function getFormattedDateFromDirectDateFormat(date) {
  let months = [
    {
      id: '01',
      value: 'Jan',
    },
    {
      id: '02',
      value: 'Feb',
    },
    {
      id: '03',
      value: 'Mar',
    },
    {
      id: '04',
      value: 'Apr',
    },
    {
      id: '05',
      value: 'May',
    },
    {
      id: '06',
      value: 'Jun',
    },
    {
      id: '07',
      value: 'Jul',
    },
    {
      id: '08',
      value: 'Aug',
    },
    {
      id: '09',
      value: 'Sep',
    },
    {
      id: '10',
      value: 'Oct',
    },
    {
      id: '11',
      value: 'Nov',
    },
    {
      id: '12',
      value: 'Dec',
    },
  ];
  let timeStr = date.replace(/\s+/g, '-'), // replacing space with hyphen
    temp = timeStr.split('T')[0].split('-'), // split with hyphen
    newFormat;

  temp[0] = temp.splice(2, 1, temp[0])[0];
  temp = temp[0] + '-' + temp[1] + '-' + temp[2] + 'T' + temp[3] + ':00.000Z';
  newFormat = temp;

  return newFormat;
}

/* Toast helper functions */
export function toast_error(obj, msg) {
  /*
  obj.$toast.open({
    message: msg,
    type: 'error',
    duration: 3000,
    dismissible: true,
    position: 'top',
  });
*/
  if (msg && msg.response) {
    if (msg.response.data.message) {
      msg = msg.response.data.message;
    } else {
      msg = msg.message;
    }
  }
  if (msg !== 'Request failed with status code 403') {
    obj.$toast.add({
      severity: 'error',
      summary: msg,
      life: 5000,
      closable: false,
      styleClass: 'toast_error_theme',
    });
  }
}
export function toast_success(obj, msg) {
  /*
  obj.$toast.open({
    message: msg,
    type: 'success',
    duration: 3000,
    dismissible: true,
    position: 'top',
  });
*/
  obj.$toast.add({
    severity: 'success',
    summary: msg,
    life: 5000,
    closable: false,
    styleClass: 'toast_success_theme',
  });
}

/* Misc helper functions */
export function getProfilePicturePath(path) {
  if (path == '' || path == null) {
    /* Replace with local jpg */
    return 'https://thumbs.dreamstime.com/t/default-avatar-profile-icon-vector-unknown-social-media-user-photo-default-avatar-profile-icon-vector-unknown-social-media-user-184816085.jpg';
  } else {
    return path;
  }
}

/* TODO:: Fix this and use wherever platform icon is needed */
/*
export function getPlatformIcon(platform) {
  let iconPath = "@/assets/icons/android_transparent.svg";
  if(platform == 'android') {
    iconPath = "@/assets/icons/android_transparent.svg"
  } else if (platform == '') {
    iconPath = "@/assets/icons/android_transparent.svg"
  } else if (platform == '') {
    iconPath = "@/assets/icons/android_transparent.svg"
  }
  return iconPath;
}*/

export function getPlatformLocaleName(platform) {
  let localePlatformName = this.$t('general').common[platform];
  if (localePlatformName == '' || localePlatformName == undefined) {
    localePlatformName = platform;
  }
  return localePlatformName;
}

export function isFormValid(obj) {
  obj.v$.$validate();
  if (!obj.v$.$error) {
    return true;
  }
  return false;
}

export function resetValidation(obj) {
  obj.$nextTick(() => {
    obj.v$.$reset();
  });
}

/* Date/Time functions */
export function getCurrentTimestampInSeconds() {
  return Math.floor(Date.now() / 1000);
}

/* local storage functions */

export function getPermissions(obj, path) {
  let permission = obj.$store.getters['account/getPermissionDetails'];
  if (permission != '' && permission != null) {
    for (let i = 0; i < path.length - 1; i++) {
      permission = permission.filter((el) => el.featureName == path[i]);
      if (permission.length > 0) {
        permission = permission[0].subFeatures;
      } else {
        return '';
      }
    }
    permission = permission.filter(
      (el) => el.featureName == path[path.length - 1],
    );
    if (permission.length > 0) {
      return permission[0].permissionName;
    } else {
      return '';
    }
  }
}
export function routingPath(obj, routes, viewType) {
  return obj.$router.push({
    path: `/${routes}`,
    query: { viewType: viewType },
  });
}

export function setThemeName(getSelectedTheme) {
  if (getSelectedTheme) {
    document.body.className = getSelectedTheme;
  } else {
    document.body.className = 'getryt';
  }
}

export function getColorCode(userName) {
  const profilePicColors = [
    { keyword: ['a', 'n'], color_code: '#7c4dff' },
    { keyword: ['b', 'o'], color_code: '#ff897f' },
    { keyword: ['c', 'p'], color_code: '#e040fb' },
    { keyword: ['d', 'q'], color_code: '#f57c01' },
    { keyword: ['e', 'r'], color_code: '#1bcf51' },
    { keyword: ['f', 's'], color_code: '#ea80fc' },
    { keyword: ['h', 'u'], color_code: '#1bbc9c' },
    { keyword: ['i', 'v'], color_code: '#c51162' },
    { keyword: ['j', 'w'], color_code: '#02bcd4' },
    { keyword: ['k', 'x'], color_code: '#ff4081' },
    { keyword: ['l', 'y'], color_code: '#5d4037' },
    { keyword: ['m', 'z'], color_code: '#536cfe' },
    { keyword: ['g', 't'], color_code: '#04a9f4' },
  ];
  if (userName) {
    const profilePicCharecter = profilePicColors.filter((el) =>
      el.keyword.includes(userName.charAt(0).toLowerCase()),
    );
    if (profilePicCharecter && profilePicCharecter.length > 0) {
      return profilePicCharecter[0].color_code;
    } else {
      return '#111';
    }
  }
  return '';
}
export function handleGetTimeZone(timeZoneOptions) {
  let timeZoneObj = [
    {
      id: '1',
      label: '(GMT-12:00) International Date Line West',
      value: 'Etc/GMT+12',
      country: 'Baker Island and Howland Island',
      countryCode: 'USA',
    },
    {
      id: '2',
      label: '(GMT-11:00) Midway Island, Samoa',
      value: 'Pacific/Midway',
      country: 'US minor outlying islands',
      countryCode: 'UM',
    },
    {
      id: '3',
      label: '(GMT-10:00) Hawaii',
      value: 'Pacific/Honolulu',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '4',
      label: '(GMT-09:00) Alaska',
      value: 'US/Alaska',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '5',
      label: '(GMT-08:00) Pacific Time (US & Canada)',
      value: 'America/Los_Angeles',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '6',
      label: '(GMT-08:00) Tijuana, Baja California',
      value: 'America/Tijuana',
      country: 'Mexico',
      countryCode: 'MX',
    },
    {
      id: '7',
      label: '(GMT-07:00) Arizona',
      value: 'US/Arizona',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '8',
      label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
      value: 'America/Chihuahua',
      country: 'Mexico',
      countryCode: 'MX',
    },
    {
      id: '9',
      label: '(GMT-07:00) Mountain Time (US & Canada)',
      value: 'US/Mountain',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '10',
      label: '(GMT-06:00) Central America',
      value: 'America/Managua',
      country: 'Nicaragua',
      countryCode: 'NIC',
    },
    {
      id: '11',
      label: '(GMT-06:00) Central Time (US & Canada)',
      value: 'US/Central',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '12',
      label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
      value: 'America/Mexico_City',
      country: 'Mexico',
      countryCode: 'MX',
    },
    {
      id: '13',
      label: '(GMT-06:00) Saskatchewan',
      value: 'Canada/Saskatchewan',
      country: 'Canada',
      countryCode: 'CA',
    },
    {
      id: '14',
      label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
      value: 'America/Bogota',
      country: 'Colombia',
      countryCode: 'CO',
    },
    {
      id: '15',
      label: '(GMT-05:00) Eastern Time (US & Canada)',
      value: 'US/Eastern',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '16',
      label: '(GMT-05:00) Indiana (East)',
      value: 'US/East-Indiana',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '17',
      label: '(GMT-04:00) Atlantic Time (Canada)',
      value: 'Canada/Atlantic',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '18',
      label: '(GMT-04:00) Manaus',
      value: 'America/Manaus',
      country: 'Brazil',
      countryCode: 'BR',
    },
    {
      id: '19',
      label: '(GMT-04:00) Santiago',
      value: 'America/Santiago',
      country: 'Chile',
      countryCode: 'CL',
    },
    {
      id: '20',
      label: '(GMT-03:30) Newfoundland',
      value: 'Canada/Newfoundland',
      country: 'Canada',
      countryCode: 'CA',
    },
    {
      id: '21',
      label: '(GMT-03:00) Brasilia',
      value: 'America/Sao_Paulo',
      country: 'Brazil',
      countryCode: 'BR',
    },
    {
      id: '22',
      label: '(GMT-03:00) Buenos Aires, Georgetown',
      value: 'America/Argentina/Buenos_Aires',
      country: 'Argentina',
      countryCode: 'AR',
    },
    {
      id: '23',
      label: '(GMT-03:00) Greenland',
      value: 'America/Godthab',
      country: 'Greenland',
      countryCode: 'GL',
    },
    {
      id: '24',
      label: '(GMT-03:00) Montevideo',
      value: 'America/Montevideo',
      country: 'Uruguay',
      countryCode: 'UY',
    },
    {
      id: '25',
      label: '(GMT-02:00) Mid-Atlantic',
      value: 'America/Noronha',
      country: 'Brazil',
      countryCode: 'BR',
    },
    {
      id: '26',
      label: '(GMT-01:00) Cape Verde Is.',
      value: 'Atlantic/Cape_Verde',
      country: 'Cape Verde',
      countryCode: 'CV',
    },
    {
      id: '27',
      label: '(GMT-01:00) Azores',
      value: 'Atlantic/Azores',
      country: 'Portugal',
      countryCode: 'PT',
    },
    {
      id: '28',
      label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
      value: 'Africa/Casablanca',
      country: 'Morocco',
      countryCode: 'MA',
    },
    {
      id: '29',
      label:
        '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon,London',
      value: 'Etc/Greenwich',
      country: 'United States',
      countryCode: 'USA',
    },
    {
      id: '30',
      label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 'Europe/Amsterdam',
      country: 'Netherlands',
      countryCode: 'NL',
    },
    {
      id: '31',
      label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      value: 'Europe/Belgrade',
      country: 'Serbia',
      countryCode: 'CS',
    },
    {
      id: '32',
      label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
      value: 'Europe/Brussels',
      country: 'Belgium',
      countryCode: 'BE',
    },
    {
      id: '33',
      label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      value: 'Europe/Sarajevo',
      country: 'Bosnia & Herzegowina',
      countryCode: 'BA',
    },
    {
      id: '34',
      label: '(GMT+01:00) West Central Africa',
      value: 'Africa/Lagos',
      country: 'Nigeria',
      countryCode: 'NG',
    },
    {
      id: '35',
      label: '(GMT+02:00) Amman',
      value: 'Asia/Amman',
      country: 'Jordan',
      countryCode: 'JO',
    },
    {
      id: '36',
      label: '(GMT+02:00) Athens, Bucharest, Istanbul',
      value: 'Europe/Athens',
      country: 'Greece',
      countryCode: 'GR',
    },
    {
      id: '37',
      label: '(GMT+02:00) Beirut',
      value: 'Asia/Beirut',
      country: 'Lebanon',
      countryCode: 'LB',
    },
    {
      id: '38',
      label: '(GMT+02:00) Cairo',
      value: 'Africa/Cairo',
      country: 'Egypt',
      countryCode: 'EG',
    },
    {
      id: '39',
      label: '(GMT+02:00) Harare, Pretoria',
      value: 'Africa/Harare',
      country: 'Zimbabwe',
      countryCode: 'ZI',
    },
    {
      id: '40',
      label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 'Europe/Helsinki',
      country: 'Finland',
      countryCode: 'FI',
    },
    {
      id: '41',
      label: '(GMT+02:00) Jerusalem',
      value: 'Asia/Jerusalem',
      country: 'Israel',
      countryCode: 'IL',
    },
    {
      id: '42',
      label: '(GMT+02:00) Minsk',
      value: 'Europe/Minsk',
      country: 'Belarus',
      countryCode: 'BY',
    },
    {
      id: '43',
      label: '(GMT+02:00) Windhoek',
      value: 'Africa/Windhoek',
      country: 'Namibia',
      countryCode: 'NA',
    },
    {
      id: '44',
      label: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
      value: 'Asia/Kuwait',
      country: 'Kuwait',
      countryCode: 'KW',
    },
    {
      id: '45',
      label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
      value: 'Europe/Moscow',
      country: 'Russia',
      countryCode: 'RU',
    },
    {
      id: '46',
      label: '(GMT+03:00) Nairobi',
      value: 'Africa/Nairobi',
      country: 'Kenya',
      countryCode: 'KE',
    },
    {
      id: '47',
      label: '(GMT+03:00) Tbilisi',
      value: 'Asia/Tbilisi',
      country: 'Georgia',
      countryCode: 'GE',
    },
    {
      id: '48',
      label: '(GMT+03:30) Tehran',
      value: 'Asia/Tehran',
      country: 'Iran',
      countryCode: 'IR',
    },
    {
      id: '49',
      label: '(GMT+04:00) Abu Dhabi, Muscat',
      value: 'Asia/Muscat',
      country: 'Oman',
      countryCode: 'OM',
    },
    {
      id: '50',
      label: '(GMT+04:00) Baku',
      value: 'Asia/Baku',
      country: 'Azerbaijan',
      countryCode: 'AZ',
    },
    {
      id: '51',
      label: '(GMT+04:00) Yerevan',
      value: 'Asia/Yerevan',
      country: 'Armenia',
      countryCode: 'AM',
    },
    {
      id: '52',
      label: '(GMT+04:30) Kabul',
      value: 'Asia/Kabul',
      country: 'Afghanistan',
      countryCode: 'AF',
    },
    {
      id: '53',
      label: '(GMT+05:00) Yekaterinburg',
      value: 'Asia/Yekaterinburg',
      country: 'Russia',
      countryCode: 'RU',
    },
    {
      id: '54',
      label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
      value: 'Asia/Karachi',
      country: 'Pakistan',
      countryCode: 'PK',
    },
    {
      id: '55',
      label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      value: 'Asia/Calcutta',
      country: 'India',
      countryCode: 'IN',
    },
    {
      id: '56',
      label: '(GMT+05:30) Sri Jayawardenapura',
      value: 'Asia/Calcutta',
      country: 'India',
      countryCode: 'IN',
    },
    {
      id: '57',
      label: '(GMT+05:45) Kathmandu',
      value: 'Asia/Katmandu',
      country: 'Nepal',
      countryCode: 'NP',
    },
    {
      id: '58',
      label: '(GMT+06:00) Almaty, Novosibirsk',
      value: 'Asia/Almaty',
      country: 'Kazakhstan',
      countryCode: 'KZ',
    },
    {
      id: '59',
      label: '(GMT+06:00) Astana, Dhaka',
      value: 'Asia/Dhaka',
      country: 'Bangladesh',
      countryCode: '',
    },
    {
      id: '60',
      label: '(GMT+06:30) Yangon (Rangoon)',
      value: 'Asia/Rangoon',
      country: 'Myanmar',
      countryCode: 'MM',
    },
    {
      id: '61',
      label: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
      value: 'Asia/Bangkok',
      country: 'Thailand',
      countryCode: 'TH',
    },
    {
      id: '62',
      label: '(GMT+07:00) Krasnoyarsk',
      value: 'Asia/Krasnoyarsk',
      country: 'Russia',
      countryCode: 'RU',
    },
    {
      id: '63',
      label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      value: 'Asia/Hong_Kong',
      country: 'Hong Kong',
      countryCode: 'HK',
    },
    {
      id: '64',
      label: '(GMT+08:00) Kuala Lumpur, Singapore',
      value: 'Asia/Kuala_Lumpur',
      country: 'Malaysia',
      countryCode: 'MY',
    },
    {
      id: '65',
      label: '(GMT+08:00) Irkutsk, Ulaan Bataar',
      value: 'Asia/Irkutsk',
      country: 'Russia',
      countryCode: 'RU',
    },
    {
      id: '66',
      label: '(GMT+08:00) Perth',
      value: 'Australia/Perth',
      country: 'Australia',
      countryCode: 'AU',
    },
    {
      id: '67',
      label: '(GMT+08:00) Taipei',
      value: 'Asia/Taipei',
      country: 'Taiwan',
      countryCode: 'TW',
    },
    {
      id: '68',
      label: '(GMT+09:00) Osaka, Sapporo, Tokyo',
      value: 'Asia/Tokyo',
      country: 'Japan',
      countryCode: 'JP',
    },
    {
      id: '69',
      label: '(GMT+09:00) Seoul',
      value: 'Asia/Seoul',
      country: 'Korea (South)',
      countryCode: 'KP',
    },
    {
      id: '70',
      label: '(GMT+09:00) Yakutsk',
      value: 'Asia/Yakutsk',
      country: 'Russia',
      countryCode: 'RU',
    },
    {
      id: '71',
      label: '(GMT+09:30) Adelaide',
      value: 'Australia/Adelaide',
      country: 'Australia',
      countryCode: 'AU',
    },
    {
      id: '72',
      label: '(GMT+09:30) Darwin',
      value: 'Australia/Darwin',
      country: 'Australia',
      countryCode: 'AU',
    },
    {
      id: '73',
      label: '(GMT+10:00) Brisbane',
      value: 'Australia/Brisbane',
      country: 'Australia',
      countryCode: 'AU',
    },
    {
      id: '74',
      label: '(GMT+10:00) Canberra, Melbourne, Sydney',
      value: 'Australia/Canberra',
      country: 'Australia',
      countryCode: 'AU',
    },
    {
      id: '75',
      label: '(GMT+10:00) Hobart',
      value: 'Australia/Hobart',
      country: 'Australia',
      countryCode: 'AU',
    },
    {
      id: '76',
      label: '(GMT+10:00) Guam, Port Moresby',
      value: 'Pacific/Guam',
      country: 'Guam',
      countryCode: 'GU',
    },
    {
      id: '77',
      label: '(GMT+10:00) Vladivostok',
      value: 'Asia/Vladivostok',
      country: 'Russia',
      countryCode: 'RU',
    },
    {
      id: '78',
      label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      value: 'Asia/Magadan',
      country: 'Russia',
      countryCode: 'RU',
    },
    {
      id: '79',
      label: '(GMT+12:00) Auckland, Wellington',
      value: 'Pacific/Auckland',
      country: 'New Zealand',
      countryCode: 'NZ',
    },
    {
      id: '80',
      label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
      value: 'Pacific/Fiji',
      country: 'Fiji',
      countryCode: 'FJ',
    },
    {
      id: '81',
      label: '(GMT+13:00) Nuku alofa',
      value: 'Pacific/Tongatapu',
      country: 'Tonga',
      countryCode: 'TO',
    },
  ];
  if (timeZoneOptions === 'COUNTRYCODE') {
    if (Intl) {
      var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return timeZoneObj.find((item) => item.value === userTimeZone);
  } else {
    return timeZoneObj;
  }
}
export const loginTokenExpired = reactive({
  loginTokenExpiredValue: false,
  open(err) {
    try {
      if (err.status == 403) {
        this.loginTokenExpiredValue = true;
      } else {
        this.loginTokenExpiredValue = false;
      }
    } catch {
      console.log('token expiry modal open catch on utils.js');
    }
  },
  close() {
    try {
      this.loginTokenExpiredValue = false;
    } catch {
      console.log('token expiry modal close catch on utils.js');
    }
    window.location.reload();
  },
});
export function checkFilterDataConditions(condition, jsondata) {
  if (jsondata) {
    const filteredArray = condition.filter((value) => jsondata.includes(value));
    if (filteredArray.length != 0) {
      return true;
    } else {
      return false;
    }
  }
}
export function inputTypeNumberOnlyAccept(e) {
  let char = String.fromCharCode(e.keyCode); // Get the character
  if (/^[0-9]*$/.test(char)) return true;
  // Match with regex
  else e.preventDefault(); // If not match, don't add to input text
}
export const headerUserData = reactive({
  firstNameLastName: null,
  datas(obj) {
    let firstName = obj.$store.getters['account/getuserfirstName'];
    let lastName = obj.$store.getters['account/getuserlastName'];
    if (firstName && lastName) {
      this.firstNameLastName = firstName + ' ' + lastName;
    }
    return this.firstNameLastName;
  },
});
export function getFormattedTimeStamp(calcTime, calcTime2, getAfterDays) {
  const today = new Date();
  const createdDate = new Date(calcTime);
  let calcDate;
  if (calcTime2) {
    const createdDate2 = new Date(calcTime2);
    calcDate = createdDate2 - createdDate;
  } else if (getAfterDays === 'FIND_AFTER_DAYS') {
    calcDate = createdDate - today;
  } else {
    calcDate = today - createdDate; // milliseconds between now & calcTime
  }
  var dayCount = Math.floor(calcDate / 86400000); // days
  var hourCount = Math.floor((calcDate % 86400000) / 3600000); // hours
  var minuteCount = Math.round(((calcDate % 86400000) % 3600000) / 60000); // minutes
  if (calcTime2) {
    let dayObj = {};
    if (dayCount > 0) {
      dayObj['days'] = dayCount + ' day(s) ';
    }
    if (hourCount > 0) {
      dayObj['hours'] = hourCount + ' hr(s) ';
    }
    if (minuteCount > 0) {
      dayObj['mins'] = minuteCount + ' min(s)';
    } else if (minuteCount) {
      dayObj['mins'] = '1 min';
    }
    return dayObj;
  } else {
    if (dayCount > 0) {
      return dayCount == 1 ? dayCount + ' day' : dayCount + ' days';
    } else if (hourCount > 0) {
      return hourCount == 1 ? hourCount + ' hr' : hourCount + ' hrs';
    } else if (minuteCount > 0) {
      return minuteCount == 1 ? minuteCount + ' min' : minuteCount + ' mins';
    } else {
      return '1 min ago';
    }
  }
}
/* global filter icon visible controll start */
export function globalFilterIconVisible(event) {
  let tempData;
  for (const key in event.filters) {
    if (key == 'global' || event.filters[key].matchMode) {
      if (!event.filters[key].value || !event.filters[key].matchMode) {
        tempData = true;
      } else {
        tempData = false;
        break;
      }
    } else {
      for (let data in event.filters[key].constraints) {
        if (!event.filters[key].constraints[data].value) {
          tempData = true;
        } else {
          tempData = false;
        }
      }
    }
    if (tempData == false) {
      break;
    }
  }
  return tempData;
}
// /* global filter icon visible controll end */
/* Datatable no data on filter */
export function noDataFoundOnFilter(event) {
  let returnData;
  if (event.filteredValue.length === 0) {
    returnData = true;
  } else {
    returnData = false;
  }
  return returnData;
}
// /* Datatable no data on filter */
export function getUsernameByAccount() {
  let loginUsername = this.$store.getters['account/getusername'];
  return loginUsername;
}
export function getOrganisationNameByAccount(obj) {
  let loginOrganisationName = obj
    ? obj.$store.getters['account/getOrganisationName']
    : this.$store.getters['account/getOrganisationName'];
  return loginOrganisationName;
}
export function getEmailByAccount(obj) {
  let loginEmailid = '';
  if (obj) {
    loginEmailid = obj.$store.getters['account/getEmailId'];
  } else {
    loginEmailid = this.$store.getters['account/getEmailId'];
  }
  return loginEmailid;
}
// enterprise registration check
export function getEnterpriseRegistrationDetails() {
  const enterpriseRegistration =
    this.$store.getters['account/getenterprisesInformation'];
  return enterpriseRegistration;
}
export function handleAndroidRegistration(
  provider,
  organisationId,
  accessToken,
) {
  let cachescope = this;
  const enterpriseRegisObj = {
    enterpriseProvider: provider,
    organisation: organisationId,
    accessToken: accessToken,
  };
  cachescope.$store
    .dispatch('enterpriseregistration/enterpriseRegister', enterpriseRegisObj)
    .then(function (res) {
      window.open(res, '_self'); // redirect to integration page
    })
    .catch(function (err) {
      cachescope.$toast.add({
        severity: 'error',
        summary: err.message,
        life: 5000,
        closable: false,
      });
    });
}
export function numericOnlyAllowed(e) {
  let char = String.fromCharCode(e.keyCode); // Get the character
  if (/^[0-9]*$/.test(char)) return true;
  // Match with regex
  else e.preventDefault(); // If not match, don't add to input text
}
export function getLoginResponseData(obj) {
  return obj.$store.getters['account/getLoginResponseData'];
}
export function getFormattedText(text) {
  return (
    text.charAt(0).toUpperCase() +
    text.slice(1).toLowerCase().replace(/_/g, ' ')
  );
}
export function animateToScrollTop(id) {
  if (id) {
    var top = $('#' + id).offset().top - 80;
    $('body, html').animate(
      {
        scrollTop: top + 'px',
      },
      0,
    );
  }
}
export function getUnderscoreFormatText(data) {
  return data.split(' ').join('_');
}
/* seconds to hours and mintues calc */
export function getGlobalTimeZone(obj) {
  return obj.$store.getters['account/getFormatedTimeZone'];
}
export function getHoursDropdownOptions(value) {
  let hour = value ? 0 : 1;
  let hoursList = [];
  for (hour; hour <= 23; hour++) {
    hoursList.push({
      label: hour,
      value: hour,
    });
  }
  return hoursList;
}
export function getMinutesDropdownOptions() {
  let minutesList = [];
  for (let min = 0; min <= 59; ) {
    minutesList.push({
      label: min < 10 ? '0' + min : min,
      value: min,
    });
    min = min + 5;
  }
  return minutesList;
}
export function getTextWidth(content) {
  var text = document.createElement('span');
  document.body.appendChild(text);
  text.style.font = 'Open sans';
  text.style.fontSize = 16 + 'px';
  text.style.height = 'auto';
  text.style.width = 'auto';
  text.style.position = 'absolute';
  text.style.whiteSpace = 'no-wrap';
  text.innerHTML = content;
  var width = Math.ceil(text.clientWidth);
  var formattedWidth = width;

  document.body.removeChild(text);
  return formattedWidth;
}
export function handleZindexForContextMenuRow() {
  $('tr').removeClass('tr_row_highlight');
  setTimeout(() => {
    if ($('.action_dots').hasClass('show')) {
      $('.show').parents('tr').addClass('tr_row_highlight');
    }
  });
}
export function numberDropdownOptions() {
  const numberDropdownTwotoThirty = [];
  let obj = {};
  for (let i = 2; i <= 30; i++) {
    obj = {
      label: i,
      value: i,
    };
    numberDropdownTwotoThirty.push(obj);
  }
  return numberDropdownTwotoThirty;
}
export function timerCountToEnableResend(
  resendId,
  startMinutes,
  startSeconds,
  timerId,
) {
  startMinutes = startMinutes < 10 ? `0${startMinutes}` : `${startMinutes}`;
  document.getElementById(timerId).innerHTML =
    startMinutes + ':' + `${startSeconds}`;
  const checkSeconds = (sec) => {
    if (sec < 10 && sec >= 0) sec = '0' + sec;
    if (sec < 0) sec = '59';
    return sec;
  };
  const startTimer = () => {
    var doc = document.getElementById(timerId);
    if (doc == null) return;
    var presentTime = document.getElementById(timerId).innerHTML;
    var timeArray = presentTime.split(/[:]+/);
    var m = timeArray[0];
    var s = checkSeconds(timeArray[1] - 1);
    var elem = $('#' + resendId);
    if (s == 59) m = m - 1;
    if (m < 0) {
      document.getElementById(timerId).innerHTML = '';
      if (elem.hasClass('actions_none')) elem.removeClass('actions_none');
      return;
    }
    if (s == 59 && m >= 0 && m < 10) m = '0' + m;
    document.getElementById(timerId).innerHTML = m + ':' + s;
    if (!elem.hasClass('actions_none')) elem.addClass('actions_none');
    setTimeout(startTimer, 1000);
  };
  startTimer();
}
export function numberDropdownOptionsStartZero() {
  const numberDropdownZeroToThirty = [];
  let obj = {};
  for (let i = 0; i <= 30; i++) {
    obj = {
      label: i === 0 ? '0' : i,
      value: i,
    };
    numberDropdownZeroToThirty.push(obj);
  }
  return numberDropdownZeroToThirty;
}
