export default [
  /* Auth module */
  {
    path: '/login',
    name: 'login.index',
    component: () => import('@/views/auth/Index.vue'),
    meta: {
      guest: false,
      role: 0,
      layout: 'Auth',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard.index',
    component: () => import('@/views/dashboard/dashboard.vue'),
    meta: {
      auth: true,
      role: 0, //Admin role
    },
  },
  /* KEEP THESE TWO ENTRIES AT LAST */
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/*',
    redirect: '/login',
  },
];
