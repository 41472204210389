import Proxy from '@/proxies/auditLogsProxy';
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getActionLogsByOrg({ state, dispatch }, param) {
      return new Proxy().getActionLogsByOrg(param);
    },
    getConsoleLogByDevice({ state, dispatch }, param) {
      return new Proxy().getConsoleLogByDevice(param);
    },
  },
};
