/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import { logout } from './actions';
import { CHECK, REGISTER, LOGIN, LOGOUT } from './mutation-types';
import $ from 'jquery';
import Axios from 'axios';
import router from '@/plugins/vue-router';

import {
  getStorageItem,
  setStorageItem,
  removeStorageItem,
  checkStorageItem,
} from '@/utils/storage.js';

/* eslint-disable no-param-reassign */
export default {
  [CHECK](state) {
    state.authenticated = checkStorageItem('accessToken');
    if (state.authenticated) {
      let accessToken = getStorageItem('accessToken');
      Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  },

  [REGISTER](state, obj) {
    let rememberme = JSON.parse(localStorage.getItem('rememberme'));
    if (rememberme) {
      //TODO:: If remember me is selected in login, accessToken is stored for 7 days. This need to be dynamic. May be can get the expiry day from server reponse.
      setStorageItem('accessToken', obj.token, 1);
    } else {
      setStorageItem('accessToken', obj.token);
    }
    Axios.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
  },

  [LOGIN](state, obj) {
    state.authenticated = true;
    if (obj.rememberme) {
      //TODO:: If remember me is selected in login, accessToken is stored for 7 days. This need to be dynamic. May be can get the expiry day from server reponse.
      setStorageItem('accessToken', obj.token, 1);
    } else {
      setStorageItem('accessToken', obj.token);
    }
    Axios.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
  },

  [LOGOUT](state) {
    state.role = 0;
    sessionStorage.removeItem('role');
    state.authenticated = false;
    removeStorageItem('accessToken');
    localStorage.removeItem('rememberme');
    localStorage.removeItem('account');
    localStorage.removeItem('organisationname');
    localStorage.removeItem('organisationid');
    localStorage.removeItem('userid');
    localStorage.removeItem('portalname');
    localStorage.removeItem('emailid');
    localStorage.removeItem('skipEnterpriseModalValue');
    localStorage.removeItem('registrationName');
    Axios.defaults.headers.common.Authorization = '';
    router.push({ path: '/login' });
  },
};
