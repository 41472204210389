import Proxy from './Proxy';

class auditLogsProxy extends Proxy {
  constructor(parameters = {}) {
    super('usr/api/v1/actions-logs', parameters);
  }

  getActionLogsByOrg(param) {
    return this.submit(
      'get',
      `${this.endpoint}/getEventFeed/forAdminConsole?organisationId=${param.organisationId}`,
    );
  }
  getConsoleLogByDevice(param) {
    return this.submit(
      'get',
      `${this.endpoint}/getActionLogsForDevice/byDeviceName?deviceName=${param.deviceName}`,
    );
  }
}

export default auditLogsProxy;
