import Proxy from './Proxy';

class AuthProxy extends Proxy {
  constructor(parameters = {}) {
    super('usr/api/v1/authz', parameters);
  }

  login({ susername, spassword, otp, accessToken }) {
    const data = {
      emailId: susername,
      password: spassword,
      accessToken: accessToken,
      otp: otp,
    };
    let api_auth = 'iam/api/v1/';
    let loginur =
      process.env.VUE_APP_API_LOCATION + api_auth + 'authz/user/login';
    return this.submit('post', loginur, data);
  }

  storetoken(param) {
    let api_auth = 'iam/api/v1/';
    let redirectionURL =
      process.env.VUE_APP_API_LOCATION +
      api_auth +
      `authz/user/verifyToken/${param}`;
    return this.submit('get', redirectionURL);
  }
  generateKey(data) {
    return this.submit(
      'get',
      `${process.env.VUE_APP_API_AUTH}user/generate-api-key/${data}`,
    );
  }

  sessionLogout(param) {
    let api_auth = 'iam/api/v1/';
    let redirectionURL =
      process.env.VUE_APP_API_LOCATION + api_auth + `authz/user/log-out`;
    return this.submit('post', redirectionURL, param);
  }
}

export default AuthProxy;
