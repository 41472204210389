import Proxy from '@/proxies/settingDynamicDatatableProxy';
import { Store } from 'vuex';
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutaionsd: {},
  actions: {
    deviceColumnValuePost({ state, dispatch }, param) {
      return new Proxy().deviceColumnValuePost(param);
    },
    updateThemeSettings({ state, dispatch }, param) {
      return new Proxy().updateThemeSettings(param);
    },
    deviceColumnValueGet({ state, dispatch }, param) {
      return new Proxy().deviceColumnValueGet(param);
    },
  },
};
