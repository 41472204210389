import Proxy from '@/proxies/auditLogDetailsProxy';
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getActionLogsByOrg({ state, dispatch }, param) {
      return new Proxy().getActionLogsByOrg(param);
    },
  },
};
