import Proxy from '@/proxies/AuthProxy';
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    storetoken({ state, dispatch }, param) {
      return new Proxy().storetoken(param);
    },
    sessionLogout({ state, dispatch }, param) {
      return new Proxy().sessionLogout(param);
    },
  },
};
