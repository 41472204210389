<template>
  <div class="position-relative apps_container sidebar_collapsed">
    <header-layout
      ref="headerLayout"
      @setThemeLayout="handleThemeSelect"
      :selectedThemeName="selectedThemeName"
      @sideBarExpandedEvent="setSideBarExpandedEvent"
    ></header-layout>
    <div class="app_dashboard">
      <div class="main-continer">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLayout from '@/components/layouts/Header.vue';
import $ from 'jquery';
import { setThemeName } from '@/utils/utils.js';
import { getStorageItem } from '@/utils/storage.js';
import {
  getUserId,
  toast_error,
  getOrganisationId,
  getEmailByAccount,
  getLoginResponseData,
} from '@/utils/utils.js';
import store from '@/store';
export default {
  name: 'PreConfigUserIndex',
  components: {
    HeaderLayout,
  },
  data: function () {
    return {
      getSelectedTheme: null,
      selectedThemeName: null,
      screenType: 'sidebarExpandControl',
      apiPath: 'iam/api/v1/',
      getFeatureListOnce: null,
      totalStoreRecords: {},
      subscriptionInfo: '',
      dataRoleType: '',
    };
  },
  methods: {
    handleThemeSelect(themeName) {
      this.selectedThemeName = themeName;
      localStorage.setItem('selectedTheme', themeName);
      this.getSelectedTheme = localStorage.getItem('selectedTheme');
      setThemeName(this.getSelectedTheme);
      window.location.reload();
    },
    setSideBarExpandedEvent(clickEvent, type) {
      if (type === 'freePlan') {
        if (this.$refs.sideBarInfo) {
          this.$refs.sideBarInfo.getSubscriptionStatus(clickEvent);
        }
      } else {
        if (this.$refs.sideBarInfo) {
          this.$refs.sideBarInfo.getSidebarExpandOnClick(clickEvent);
        }
      }
    },
    async verifyTokenhit() {
      var token = getStorageItem('accessToken');
      // callAuthenticationData(token, 'sidebarEventTriggerTrue');
      var getSidemenuResposeData = setInterval(() => {
        if ($('.sidebar_menu').hasClass('sidebar_menu_event_trigger')) {
          this.sideBarOrderRearrangeGet();
          clearInterval(getSidemenuResposeData);
          $('.sidebar_menu').removeClass('sidebar_menu_event_trigger');
        }
      }, 1000);
    },
    setSubscriptionInfo() {
      const cachescope = this;
      const apiPayload = {
        organisationId: getOrganisationId(this),
        firstName: this.$store.getters['account/getuserfirstName'],
        lastName: this.$store.getters['account/getuserlastName'],
        email: getEmailByAccount(this),
        organisationName: this.$store.getters['account/getOrganisationName'],
        preferedCurrencyFormat: 'INR',
      };
      this.$store
        .dispatch('subscription/createSubscription', apiPayload)
        .then((res) => {
          cachescope.$router.push({ path: '/dashboard' });
          const token = getStorageItem('accessToken');
        })
        .catch((err) => {
          toast_error(cachescope, err.message);
        });
    },
  },
  beforeCreate() {
    this.getSelectedTheme = localStorage.getItem('selectedTheme');
    setThemeName(this.getSelectedTheme);
  },
  mounted() {
    this.getSelectedTheme = localStorage.getItem('selectedTheme');
    this.selectedThemeName = this.getSelectedTheme
      ? this.getSelectedTheme
      : 'getryt';
    this.totalStoreRecords = getLoginResponseData(this);
    // if (this.totalStoreRecords.subscriptionInformation) {
    //   this.subscriptionInfo =
    //     this.totalStoreRecords.subscriptionInformation.subscriptionStatus;
    // }
    this.dataRoleType = this.$store.getters['account/getuserroles'];
    // hightlight selected theme end
  },
  created() {
    var getCallApiCookie = this.$cookies.get('getFeatureListOnce');
    if (getCallApiCookie) {
      this.getFeatureListOnce = getCallApiCookie;
    } else {
      this.getFeatureListOnce = true;
    }
    // if (this.getFeatureListOnce == true) {
    //   var subscriptionInformation =
    //     this.$store.getters['account/getSubscriptionInformation'];
    //   this.dataRoleType = this.$store.getters['account/getuserroles'];
    //   if (
    //     subscriptionInformation == null &&
    //     this.dataRoleType == 'ORGANIZATION_ADMIN'
    //   ) {
    //     this.setSubscriptionInfo();
    //   } else {
    //     // this.getSubscriptionFeatureDetails();
    //   }
    //   this.$cookies.set('getFeatureListOnce', false, 1 * 6 * 60 * 1000);
    // }
    if (
      this.$route.query.status === 'success' &&
      this.$route.path === '/subscription'
    ) {
      setTimeout(() => {
        this.subscriptionInformation =
          this.$store.getters['account/getSubscriptionInformation'];
        this.subscriptionInfo = this.subscriptionInformation.subscriptionStatus;
        if (this.$refs.sideBarInfo) {
          this.$refs.sideBarInfo.getSubscriptionStatus(this.subscriptionInfo);
        }
        if (this.$refs.headerLayout) {
          this.$nextTick(() => {
            this.$refs.headerLayout.getSubscriptionStatusForHeader(
              this.subscriptionInfo,
            );
          });
        }
      }, 1600);
    }
  },
};
</script>
