import { createApp } from 'vue';
import App from './App.vue';

import store from './store';
import Axios from 'axios';
//import VueAxios from 'vue-axios';
//import auth from './plugins/auth';

/* ============
 * Plugins
 * ============
 */
//import './plugins/vuex';
//import './plugins/axios';
import i18n from './plugins/vue-i18n';
import router from './plugins/vue-router';
import './plugins/bootstrap';
//import './plugins/font-awesome';
//import './plugins/register-service-worker';
//import './plugins/toast';

// import moment from 'moment';
// import moment from 'moment-timezone';

/* ===================
 * PrimeVue Components
 * ===================
 */
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import vueCountryRegionSelect from 'vue3-country-region-select';
import VueCookies from 'vue-cookies';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css?2403231320';

import './assets/scss/app.scss?0310232100';

store.dispatch('auth/check');

const app = createApp(App);

//app.use(auth, { router });
//auth({ router, app });

app.use(PrimeVue);
app.use(ToastService);

app.use(router);
app.use(i18n);
app.use(store);
app.use(vueCountryRegionSelect);
app.use(VueCookies);
//app.use(Axios);

Axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;
Axios.defaults.headers.common.Accept = 'application/json';
//app.config.globalProperties.$http =  Axios;
//console.log(Axios.defaults.baseURL);
//console.log(app.config.globalProperties.$http)
//console.log(app.config.globalProperties.$auth);
/*
this.$http = axios;
Object.defineProperty(app.config.globalProperties, '$http', {
  get() {
    return axios;
  },
});
*/

//app.config.globalProperties.$http =  'VueAxios';
//app.use(axios)

app.component('InputText', InputText);
app.component('Button', Button);
app.component('Toast', Toast);

// app.config.globalProperties.$moment = moment;

app.mount('#app');
