/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import Vue from 'vue';
import store from '@/store';
import * as types from './mutation-types';
import VueRouter from 'vue-router';
import Proxy from '@/proxies/AuthProxy';
import { method } from 'lodash';
import router from '@/plugins/vue-router';
import { loginTokenExpired } from '@/utils/utils.js';
import { setStorageItem } from '@/utils/storage.js';

export const check = ({ commit }, payload) => {
  commit(types.CHECK);
  store.dispatch('account/find');
};

export const register = ({ commit }, payload) => {
  let loginObj = {
    token: payload,
  };
  commit(types.REGISTER, loginObj);
  // Vue.router.push({ path: '/dashboard' }); // NavigationDuplicated: Avoided redundant navigation to current location: "/dashboard"
};

export const login = ({ commit }, payload) => {
  var dataarr = {
    susername: payload.emailId,
    otp: payload.otp,
  };
  if (payload.accessToken) {
    dataarr['accessToken'] = payload.accessToken;
  } else if (payload.password) {
    dataarr['spassword'] = payload.password;
  }
  let rememberme = payload.rememberme;
  let expiryToken = payload.expiryToken;
  localStorage.setItem('rememberme', JSON.stringify(payload.rememberme));
  return new Promise((resolve, reject) => {
    new Proxy()
      .login(dataarr)
      .then((response) => {
        localStorage.setItem('userid', response.userId);
        localStorage.setItem('organisationname', response.organisationName);
        if (rememberme) {
          //TODO:: If remember me is selected in login, accessToken is stored for 7 days. This need to be dynamic. May be can get the expiry day from server reponse.
          setStorageItem('accessToken', response.accessToken, 1);
        } else {
          setStorageItem('accessToken', response.accessToken);
        }
        let IdToken = response.accessToken;
        // let organisationPortal = 'http://localhost:8080';
        let organisationPortal = 'https://admin.quantem.io';
        let getBrowserURL = window.location.origin;
        const roles = {
          SUPER_ADMIN: false,
          PARTNER: false,
          GUEST: false,
        };

        let loginObj = {
          token: response.accessToken,
          roles: roles,
          rememberme: rememberme,
        };

        commit(types.LOGIN, loginObj);
        store.dispatch('account/saveaccount', response);
        if (!expiryToken) {
          window.location.href = `${organisationPortal}/dashboard`;
        } else {
          try {
            loginTokenExpired.close();
          } catch {
            console.log('login token expired catch on action.js');
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = ({ commit }) => {
  commit(types.LOGOUT);
};
export const generateKey = ({ commit }, payload) => {
  return new Proxy().generateKey(payload);
};
export default {
  check,
  register,
  login,
  logout,
  generateKey,
};
