import Proxy from './Proxy';
class IamProxy extends Proxy {
  constructor(parameters = {}) {
    super('iam/api/v1', parameters);
  }
  getOrganisationList(param) {
    return this.submit('get', `${this.endpoint}/user/find-all`);
  }
}

export default IamProxy;
