import Proxy from './Proxy';

class ReportProxy extends Proxy {
  constructor(parameters = {}) {
    super('report/api/v1/', parameters);
  }

  organisationWiseAdminConsoleData(param) {
    return this.submit(
      'get',
      `${this.endpoint}scheduled-reports/getAll/orgDetails`,
    );
  }
}

export default ReportProxy;
