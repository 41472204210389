import Proxy from './Proxy';
class settingDynamicDatatableProxy extends Proxy {
  constructor(parameter = {}) {
    super('iam/api/v1', parameter);
  }
  deviceColumnValuePost(param) {
    this.submit('post', `${this.endpoint}/user-preference/create`, param);
  }
  updateThemeSettings(param) {
    this.submit('patch', `${this.endpoint}/user/updateThemeSetting`, param);
  }
  deviceColumnValueGet(param) {
    this.submit(
      'get',
      `${this.endpoint}/user-preference/findOne?userId=${param.userId}&screen=${param.screen}`,
    );
  }
}
export default settingDynamicDatatableProxy;
