import Proxy from '@/proxies/IamProxy';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAdminSettingDetail({ state, dispatch }, param) {
      return new Proxy().getAdminSettingDetail(param);
    },
    postAdminSettingData({ state, dispatch }, param) {
      return new Proxy().postAdminSettingData(param);
    },
    getOrganisationList({ state, dispatch }, param) {
      return new Proxy().getOrganisationList(param);
    },
  },
};
